import { ApolloClient, createHttpLink, InMemoryCache, from, split } from '@apollo/client/core'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { fetchAuthSession } from 'aws-amplify/auth'
import { GetCollection_getCollection_collaborators, GetCollection_getCollection_auction } from '#graphql/types/GetCollection'
import { GetToken_getToken_collection } from '#graphql/types/GetToken'
import useSockets from '#composables/use-sockets'
import useVersionReloader from '#composables/use-version-reloader'
import { WindowWithWaf } from '#types'
import { el } from 'date-fns/locale'
// import { datadogRum } from '@datadog/browser-rum'

const { serverVersion, cachedTime } = useVersionReloader()
/**
 * The Apollo cache.
 */
const nullableType = <T> () => {
  return {
    read (existing: null) {
      return existing
    },
    merge (existing: T | null, incoming: T) {
      if (incoming) {
        return incoming
      } else {
        return existing
      }
    },
  }
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getToken: {
          merge (_, incoming) {
            return incoming
          },
        },
        getCommunity: {
          merge (_, incoming) {
            return incoming
          },
        },
      },
    },
    Auction: {
      keyFields: ['collectionId', 'comingSoonDate'],
    },
    CollectionBenefit: {
      keyFields: ['collectionId', 'benefitId'],
    },
    Collection: {
      fields: {
        requestedChanges: nullableType<string>(),
        collaborators: nullableType<GetCollection_getCollection_collaborators[]>(),
        auction: nullableType<GetCollection_getCollection_auction[]>(),
      },
    },
    Token: {
      keyFields: ['id', 'collectionId', 'accountId'],
      fields: {
        collection: {
          merge (existing: GetToken_getToken_collection | null, incoming: GetToken_getToken_collection) {
            if (incoming) {
              return incoming
            } else {
              return existing
            }
          },
        },
      },
    },
    Account: {
      fields: {
        verified (raw: boolean | null) { return raw ?? null },
      },
    },
    AccountActivity: {
      fields: {
        fromAccount: nullableType<string>(),
        toAccount: nullableType<string>(),
      },
    },
  },
})

const customFetch = async (uri: URL | RequestInfo, options: RequestInit | undefined) => {

  const headers = options ? { ...options.headers, 'x-version-hash-cache-time': cachedTime.value, 'x-version-hash': serverVersion.value } : { 'x-version-hash-cache-time': cachedTime.value, 'x-version-hash': serverVersion.value }
  if (options) {
    options.headers = headers
  }

  const response = await fetch(uri, options)
  const responseClone = response.clone()
  const hash = responseClone.headers.get('x-version-hash') as string
  const hashedTime = responseClone.headers.get('x-version-hash-cache-time') as string

  serverVersion.value = hash
  cachedTime.value = hashedTime
  return response
}

/**
 * The terminating link for graph requests.
 */
const httpLink = createHttpLink({
  uri: (import.meta.env.VITE_GRAPH_URL as string),
  fetch: customFetch,
})

/**
 * Handles outgoing headers and
 * 1. checks for a valid WAF token
 * 2. calculates a reCaptcha for certain operations
 * 3. sets the JWT token if the user is logged in.
 */
const authLink = setContext(async (context, { headers }) => {
  try {
    if (!(window as WindowWithWaf)?.AwsWafIntegration.hasToken()) {
      await (window as WindowWithWaf)?.AwsWafIntegration.getToken()
    }
  } catch (error) {
    console.error('Failed to get WAF token', error)
    throw new Error('Unable to complete request')
  }

  try {
    // const currentSession = await Auth.currentSession()
    // const accessToken = currentSession.getAccessToken()
    // const token = accessToken.getJwtToken()

    const { accessToken } = (await fetchAuthSession()).tokens ?? {}

    return {
      headers: {
        ...headers,
        ...(accessToken ? { 'Authorization': `Bearer ${accessToken}` } : {}),
      },
    }
  } catch (error) {
    // this is okay, just no logged in user at the moment
    // console.log('authlink', error)
  }
  return {
    headers,
  }
})

/**
 * Handles errors coming back from graph.
 *
 * The main use case is when a user's accessToken has expired and is required to
 * refresh it.
 */
const errorHandler = onError(({ graphQLErrors }) => {
  console.log('GraphQL Server error', graphQLErrors)
})


/**
 * Handles retrying a graph request up to 3 times.
 *
 * This is useful for when a token refresh has been requested and we want the
 * failed request to replay.
 */
const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 10000,
  },
  attempts: {
    max: 3,
    retryIf: (error) => !error,
  },
})


/**
 * The websocket client needed for graph subscriptions.
 */
const wsClient = new SubscriptionClient((import.meta.env.VITE_GRAPH_WS_URL as string), {
  minTimeout: 10000,
  timeout: 20000,
  reconnect: true,
})

wsClient.onConnected(() => {
  const { isReady } = useSockets()
  isReady.value = true
})

wsClient.onDisconnected(() => {
  const { isReady } = useSockets()
  isReady.value = false
})

const wsLink = new WebSocketLink(wsClient)


/**
 * Splits communication between normal graph requests and websocket
 * communications.
 */
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  from([errorHandler, retryLink, authLink, httpLink])
)


/**
 * Instantiate a new Apollo client.
 */
export const apolloClient = new ApolloClient({
  link,
  cache,
})
