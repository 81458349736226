import { ref, computed } from 'vue'

const serverVersion = ref('')
const htmlVersion = ref('')
const cachedTime = ref('')
const reload = computed(() => htmlVersion.value !== serverVersion.value)

export default function useVersionReloader () {
  return {
    serverVersion,
    htmlVersion,
    reload,
    cachedTime,
  }
}
