<template>
  <div
    class="fixed right-0 md:bottom-6 md:right-6 rounded shadow-lg overflow-visible bg-white px-6 md:px-16 py-10 md:py-16 flex flex-col w-full md:w-[500px] rounded-none md:rounded-3xl md:h-auto max-h-[calc(100vh-56px)]"
    :class="isAuthenticated ? 'bottom-16' : 'bottom-0'"
  >
    <Body
         
      size="sm"
      class="mb-2 lg:mb-4"
    >
      A new version of Remx is available. Refresh to update.
    </Body>
        
    <GlobalButton
      full-width
      size="sm"
      priority="primary-light"
      @click.prevent="handleRefreshApp"
    >
      Refresh
    </GlobalButton>
  </div>
</template>

<script setup lang="ts">
import useLogin from '#composables/use-login'
import useVersionReloader from '#composables/use-version-reloader'

import Body from '#components/typography/body'
import GlobalButton from '#components/global/global-button'

const { serverVersion } = useVersionReloader()
const { isAuthenticated } = useLogin()

const handleRefreshApp = () => {
  // Get the current URL
  const currentUrl = new URL(window.location.href)

  // Set or update the query string parameter
  currentUrl.searchParams.set('version', serverVersion.value)

  // Reload the page with the new query string parameter
  window.location.href = currentUrl.toString()
}
</script>
