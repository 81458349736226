<template>
  <Modal
    :is-open="isOpen"
    :content-overflow="true"
    :hide-action-buttons="true"
    title="This account does not exist"
    :is-loading="false"
    :persistent-on-off="true"
    @close="handleClose"
  >
    <div class="w-full flex justify-center flex-col">
      <Body v-if="connectionType !== 'crossmint'">
        It looks like you were trying to login with {{ connectionType === 'email' ? 'an email' : 'a wallet' }} that doesn't exist on Remx yet.  {{ connectionType === 'wallet' ? 'If you already have an account on Remx, you can link this wallet to your wallet in your profile settings.' : '' }}
        <br>
        <br>
        If this was correct, please create an account to continue.  If this was a mistake, please close this window and try logging in with a different account.
      </Body>

      <Body v-else>
        Oops. It looks like you’re trying to log in with a Crossmint account that doesn’t exist. Unfortunately Remx doesn’t support new Crossmint accounts being created.
        <br>
        <br>
        Please close this window and try logging in with a different account.
      </Body>

      <div class="w-full flex justify-center mt-4 space-x-4">
        <GlobalButton
          priority="secondary-light"
          full-width
          @click="handleClose"
        >
          Close
        </GlobalButton>

        <GlobalButton
          v-if="connectionType !== 'crossmint'"
          full-width
          @click="handleCreateAccount"
        >
          Create account
        </GlobalButton>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { type PropType } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import Modal from '#components/modal'
import Body from '#components/typography/body'
import GlobalButton from '#components/global/global-button'

const emit = defineEmits(['close'])

const router = useRouter()
const route = useRoute()

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },

  connectionType: {
    type: String as PropType<'wallet' | 'crossmint' | 'email'>,
    default: 'wallet',
  },
})

const handleClose = () => {
  emit('close')
}

const handleCreateAccount = () => {
  emit('close')

  router.push({ name: 'start-create-account', query: { page: route.path } })
}
</script>
