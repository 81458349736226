import { computed, ref } from 'vue'

import QrcodeVue from 'qrcode.vue'

import { createAppClient, viemConnector } from '@farcaster/auth-client'

const appClient = createAppClient({
  relay: 'https://relay.farcaster.xyz',
  ethereum: viemConnector(),
})

const id = ref<string | null>(null)
const address = ref<any | null>(null)

const channelTokenValue = ref<any>(null)
const url = ref<any>(null)
const username = ref<any>(null)
const pfpUrl = ref<any>(null)
const bio = ref<any>(null)
const displayName = ref<any>(null)
const custody = ref<any>(null)

export default function useFarcaster () {

  const createChannel = async () => {
    return await appClient.createChannel({
      siweUri: `https://${import.meta.env.VITE_BASE_DOMAIN_NAME}`,
      domain: `${import.meta.env.VITE_BASE_DOMAIN_NAME}`,
    })
  }

  const waitToSign = async () => {
    try {
      channelTokenValue.value = null
      url.value = null
      const { data: { channelToken, url: channelUrl, nonce } } = await createChannel()
      url.value = channelUrl

      channelTokenValue.value = channelToken
      const result = await appClient.watchStatus({
        channelToken: channelTokenValue.value,
        timeout: 60_000,
        interval: 1_000,
        onResponse: ({ response, data }) => {
          // console.log('useFarcaster pull response ', response)
          // console.log('useFarcaster pull data ', data)
        },
      })

      const isError = result.isError

      if (isError) {
        return false
      }

      const { nonce: nonceResult, message, signature, username: farcasterUsername, pfpUrl: farcasterPfpUrl, bio: farcasterBio, displayName: farcasterDisplayName, custody: farcasterCustody } = result.data

      username.value = farcasterUsername || ''
      pfpUrl.value = farcasterPfpUrl || ''
      bio.value = farcasterBio || ''
      displayName.value = farcasterDisplayName || ''
      custody.value = farcasterCustody || ''

      const signResult = await appClient.verifySignInMessage({
        nonce: nonceResult,
        domain: `${import.meta.env.VITE_BASE_DOMAIN_NAME}`,
        message: message || '',
        signature: signature || `0x`,
      })

      const { data, success, fid } = signResult

      if (!success) {
        console.error('Error:', signResult.error)
        return false
      }

      id.value = fid.toString()
      address.value = data.address.toLocaleLowerCase()

      return true
    }

    catch (e) {
      console.error('Error:', e)
      return false
    }

  }

  return {
    id,
    url,
    address,
    username,
    pfpUrl,
    bio,
    displayName,
    custody,
    waitToSign,
  }
}
