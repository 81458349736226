<template>
  <Modal
    title="Invite only"
    :is-open="isOpen"
    :hide-action-buttons="true"
    @close="close"
  >
    <Body>
      Creating Collectible Moments is by invitation only.
      <br>
      <br>
      To apply, visit our <a
        href="https://x.com/remx_xyz"
        target="_blank"
        rel="noopener noreferrer"
        class="underline underline-offset-2 hover:no-underline"
      >X profile</a> and look for the featured application post.
    </Body>
  </Modal>
</template>

<script setup lang="ts">
import Body from '#components/typography/body'
import Modal from '#components/modal'

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}
</script>
