const Feed = () => import('#pages/feed/feed.vue')
const About = () => import('#pages/root/about.vue')
const Create = () => import('#pages/root/create.vue')
const Collect = () => import('#pages/root/collect.vue')
const Leaderboard = () => import('#pages/root/leaderboard.vue')
const TermsOfService = () => import('#pages/root/termsOfService.vue')
const PrivacyPolicy = () => import('#pages/root/privacyPolicy.vue')
const PageNotFound = () => import('#pages/root/page-not-found.vue')
const Home = () => import('#pages/root/home.vue')
const TwitterSignIn = () => import('#pages/root/twitter-sign-in.vue')
const CreateWeeklySponsor = () => import('#pages/root/weekly-sponsor/create-weekly-sponsor.vue')
const EditWeeklySponsor = () => import('#pages/root/weekly-sponsor/edit-weekly-sponsor.vue')

import { useRouter, type RouteLocationNormalized, type NavigationGuard } from 'vue-router'

import useLogin from '#composables/use-login'

const weeklySponsorGuard: NavigationGuard = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const { isAuthReady, isAdmin } = useLogin()
  const router = useRouter()

  await isAuthReady()

  // if users is not an admin, redirect to home
  if (!isAdmin.value) {
    router.push({ name: 'home' })
  }
}

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Create. Collect. Earn.',
    },
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: 'about',
    },
    component: About,
  },
  {
    path: '/create',
    name: 'create',
    meta: {
      title: 'create',
    },
    component: Create,
  },
  {
    path: '/collect',
    name: 'collect',
    meta: {
      title: 'collect',
    },
    component: Collect,
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    meta: {
      title: 'leaderboard',
    },
    component: Leaderboard,
  },
  {
    path: '/terms-of-service',
    name: 'termsOfService',
    meta: {
      title: 'terms of service',
    },
    component: TermsOfService,
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    meta: {
      title: 'privacy policy',
    },
    component: PrivacyPolicy,
  },
  {
    path: '/page-not-found',
    name: 'pageNotFound',
    meta: {
      title: 'Page Not Found',
    },
    component: PageNotFound,
  },
  {
    path: '/feed',
    name: 'feed',
    meta: {
      title: 'Feed',
    },
    component: Feed,
  },
  {
    path: '/twitter-sign-in',
    name: 'twitterSignIn',
    meta: {
      title: 'Twitter Sign In',
    },
    component: TwitterSignIn,
  },
  {
    path: '/weekly-sponsor/create',
    name: 'create-weekly-sponsor',
    meta: {
      title: 'Create Weekly Sponsor',
    },
    beforeEnter: weeklySponsorGuard,
    component: CreateWeeklySponsor,
  },
  {
    path: '/weekly-sponsor/edit/:profileSlug',
    name: 'edit-weekly-sponsor',
    meta: {
      title: 'Edit Weekly Sponsor',
    },
    beforeEnter: weeklySponsorGuard,
    component: EditWeeklySponsor,
  },
]
